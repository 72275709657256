import type { VNode } from 'vue';
import IconCheckMark from '@/assets/icons/icon-checkmark.svg?component';
import IconCircle from '@/assets/icons/icon-circle.svg?component';
import IconKokkiKlubiWhite from '@/assets/icons/icon-kokkiklubi-white.svg?component';
import IconKokkiKlubiChecked from '@/assets/icons/icon-kokkiklubi-checked.svg?component';

export const loyaltyLevels = [
  {
    level: 1,
    titleKey: 'loyalty_program.level_1',
    activeIcon: IconCheckMark,
    icon: IconCircle,
    progress: 'w-0',
  },
  {
    level: 2,
    titleKey: 'loyalty_program.level_2',
    activeIcon: IconCheckMark,
    icon: IconCircle,
    progress: 'w-1/3',
  },
  {
    level: 3,
    titleKey: 'loyalty_program.level_3',
    activeIcon: IconCheckMark,
    icon: IconCircle,
    progress: 'w-2/3',
  },
  {
    level: 4,
    titleKey: 'loyalty_program.level_4',
    activeIcon: IconKokkiKlubiChecked,
    icon: IconKokkiKlubiWhite,
    progress: 'w-3/3',
  },
] as const;

export default defineComponent({
  name: 'LoyaltyProgramProgressBar',
  props: {
    loyaltyLevel: {
      type: Number,
      default: 1,
    },
    weekPaused: {
      type: Boolean,
      default: false,
    },
  },
  render(): VNode | null {
    return (
      <ul class="z-1 relative flex w-full items-center justify-between">
        {loyaltyLevels.map((level) => (
          <li>
            {this.loyaltyLevel >= level.level && !this.weekPaused ? (
              <level.activeIcon height="1rem" width="1rem" />
            ) : (
              <level.icon height="1rem" width="1rem" />
            )}
          </li>
        ))}
        <div class="-z-1 absolute left-1 h-2 w-[97%] bg-white">
          <div
            class={`bg-rb-yellow h-full ${
              loyaltyLevels[this.loyaltyLevel - 1].progress
            }`}
          />
        </div>
      </ul>
    );
  },
});
